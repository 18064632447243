import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';

// Pages
import NotFound from './pages/Page404';
import Transactions from './pages/Transactions';
import Home from './pages/Home';
import SendCrypto from './pages/SendCrypto';
import Help from './pages/Help';

export default function Router() {
  return useRoutes([
    {
      path: '/',
      element: <DashboardLayout />,
      children: [
        { path: '/', element: <Home /> },
        { path: '/transactions', element: <Transactions /> },
        { path: '/send', element: <SendCrypto /> },
        { path: '/help', element: <Help /> },
      ],
    },
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
