import { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { Card } from '@mui/material';
import swingInTopForward from '../animations/swingInTopForward';

const StyledAnimatedCard = styled(Card)(({ animateCard }) => ({
  animation: animateCard && `${swingInTopForward} 1.5s cubic-bezier(0.175, 0.885, 0.320, 1.275) both`,
}));

const AnimatedCard = ({ children }) => {
  const [animateCard, setAnimateCard] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      // animation
      setAnimateCard(true);
    }, 100);
  }, [animateCard]);

  return <StyledAnimatedCard animateCard={animateCard}>{children}</StyledAnimatedCard>;
};

export default AnimatedCard;
