import * as Yup from 'yup';
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  Typography,
} from '@mui/material';
import { Form as FormikForm, Formik } from 'formik';
import { useContext, useState } from 'react';
import { isEmpty } from 'lodash';
import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material/styles';
import StatusIcon from '../../../components/StatusIcon';
import Dialog from '../../../components/Dialog';
import { currencies } from '../../../utils/constants';
import { TransactionContext } from '../../../context/CryptoNdani';
import LoadingDialog from '../../../components/LoadingDialog';

const SendCryptoSchema = Yup.object().shape({
  token: Yup.string().required('Please select the currency you wish to send.'),
  recipient: Yup.string().required('Please provide the recipient address.'),
  amount: Yup.number().required('Please enter the amount to send.'),
});

// style constant
const useStyles = makeStyles(() => ({
  signDivider: {
    flexGrow: 1,
  },
  dialogContent: {
    textAlign: 'center',
  },
  loginInput: {
    marginTop: 8,
    marginBottom: 8,
  },
}));

const SendCryptoForm = () => {
  const classes = useStyles();
  const theme = useTheme();

  const [checked, setChecked] = useState(false);

  const { sendTransactionMutation, isLoading } = useContext(TransactionContext);

  const [sendCryptoDetails, setSendCryptoDetails] = useState({
    open: false,
    status: false,
    message: '',
    body: '',
  });

  const buttonDisabledStatus = (errors, values) => {
    let status = true;
    if (
      isEmpty(errors) &&
      values.token !== '' &&
      values.recipient !== '' &&
      values.amount !== '' &&
      checked &&
      values.amount > 0
    ) {
      status = false;
    }
    return status;
  };

  const { open, status, message, body } = sendCryptoDetails;
  const closeDialog = () => {
    setSendCryptoDetails({ open: false, status: false, message: '', body });
  };

  return (
    <Formik
      initialValues={{
        token: currencies[0].address,
        recipient: '',
        amount: 0,
      }}
      validationSchema={SendCryptoSchema}
      onSubmit={async (values) =>
        sendTransactionMutation({ token: values.token, recipient: values.recipient, amount: values.amount }).then(
          (response) => {
            // console.log(response); // debug

            const { transactionHash, err, status } = response;

            if (status) {
              setSendCryptoDetails({
                open: true,
                status: true,
                message: `Transaction Successful, Transfer initiated with transaction ID: ${transactionHash}`,
                body: '',
              });
            } else {
              setSendCryptoDetails({ open: true, status: false, message: err, body: '' });
            }
          }
        )
      }
    >
      {({ errors, setFieldValue, values }) => (
        <FormikForm>
          <Dialog
            open={open}
            modalContent={
              <Box className={classes.dialogContent}>
                <StatusIcon status={status ? 'success' : 'error'} text={status ? 'Crypto Sent' : 'Sending failed!'} />
                <Typography variant="body1"> {message}</Typography>
              </Box>
            }
            modalActions={
              <Button variant="contained" onClick={() => closeDialog()} color="primary" autoFocus>
                Close
              </Button>
            }
            handleClose={closeDialog}
          />
          <LoadingDialog isLoading={isLoading} />
          <Grid container direction="row" spacing={3}>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              <Typography sx={{ mb: 3, color: theme.palette.primary.main }}>Recipient Info.</Typography>
              <FormControl fullWidth error={Boolean(errors.recipient)} className={classes.loginInput}>
                <InputLabel htmlFor="standard-weight-helper-recipient-address">Recipient Crypto Address</InputLabel>
                <OutlinedInput
                  id="standard-weight-helper-recipient-address"
                  type="text"
                  value={values.recipient}
                  name="recipient"
                  onChange={(e) => {
                    setFieldValue('recipient', e.target.value, true);
                  }}
                  label="Recipient Crypto Address"
                  inputProps={{
                    classes: {
                      notchedOutline: classes.notchedOutline,
                    },
                  }}
                />
                {errors.recipient && (
                  <FormHelperText error id="standard-weight-helper-recipient-address">
                    {' '}
                    {errors?.recipient}{' '}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              <Typography sx={{ mb: 3, color: theme.palette.primary.main }}>Currency.</Typography>

              <FormControl fullWidth error={Boolean(errors.token)} className={classes.loginInput}>
                <InputLabel htmlFor="standard-weight-helper-currency">Currency</InputLabel>
                <Select
                  labelId="standard-weight-helper-currency"
                  id="standard-weight-helper-currency"
                  vvalue={values.token}
                  label="Currency"
                  onChange={(e) => {
                    setFieldValue('token', e.target.value, true);
                  }}
                >
                  {currencies.map((currency) => (
                    <MenuItem value={currency?.address} key={currency?.address}>
                      {currency?.name} - ({currency?.symbol})
                    </MenuItem>
                  ))}
                </Select>
                {errors.token && (
                  <FormHelperText error id="standard-weight-helper-currency">
                    {errors?.token}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              <Typography sx={{ mb: 3, color: theme.palette.primary.main }}>Amount</Typography>
              <FormControl fullWidth error={Boolean(errors.amount)} className={classes.loginInput}>
                <InputLabel htmlFor="standard-weight-helper-amount">Amount</InputLabel>
                <OutlinedInput
                  id="standard-weight-helper-amount"
                  type="number"
                  value={values.amount}
                  name="amount"
                  onChange={(e) => {
                    setFieldValue('amount', e.target.value, true);
                  }}
                  label="Amount"
                  inputProps={{
                    classes: {
                      notchedOutline: classes.notchedOutline,
                    },
                  }}
                />
                {errors.amount && (
                  <FormHelperText error id="standard-weight-helper-amount">
                    {errors.amount}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
          </Grid>

          <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={checked}
                  onChange={(event) => setChecked(event.target.checked)}
                  name="checked"
                  color="primary"
                />
              }
              label="I Accept the terms and conditions."
            />
          </Stack>

          <Box
            sx={{
              mt: 2,
            }}
          >
            <Button
              disableElevation
              disabled={buttonDisabledStatus(errors, values)}
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              color="secondary"
            >
              {isLoading ? 'Sending transaction, please wait...' : 'Send Crypto Now'}
            </Button>
          </Box>
        </FormikForm>
      )}
    </Formik>
  );
};

export default SendCryptoForm;
