import { useState } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { BottomNavigation, BottomNavigationAction, Paper } from '@mui/material';
import CurrencyBitcoinIcon from '@mui/icons-material/CurrencyBitcoin';
import HistoryIcon from '@mui/icons-material/History';
import HelpIcon from '@mui/icons-material/Help';

export default function MyBottomNavigation() {
  const { pathname } = useLocation();

  let currentMenuSelection;
  switch (pathname) {
    case '/':
      currentMenuSelection = '';
      break;
    case '/send':
      currentMenuSelection = 0;
      break;
    case '/transactions':
      currentMenuSelection = 1;
      break;
    case '/help':
      currentMenuSelection = 2;
      break;
    default:
      currentMenuSelection = 1;
  }
  const [selectedPage, setSelectedPage] = useState(currentMenuSelection);

  return (
    <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, zIndex: 3, height: 60 }} elevation={3}>
      <BottomNavigation
        showLabels
        value={selectedPage}
        onChange={(event, newValue) => {
          setSelectedPage(newValue);
        }}
      >
        <BottomNavigationAction label="Send Crypto" component={RouterLink} to="/send" icon={<CurrencyBitcoinIcon />} />
        <BottomNavigationAction label="Transactions" component={RouterLink} to="/transactions" icon={<HistoryIcon />} />
        <BottomNavigationAction label="Get Help" component={RouterLink} to="/help" icon={<HelpIcon />} />
      </BottomNavigation>
    </Paper>
  );
}
