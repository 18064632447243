import Iconify from '../../components/Iconify';

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

const navConfig = [
  {
    title: 'My Transactions',
    path: '/transactions',
    icon: getIcon('eva:pie-chart-2-fill'),
  },
  {
    title: 'Send Crypto',
    path: '/send',
    icon: getIcon('eva:people-fill'),
  },
  {
    title: 'Get Help Now',
    path: '/help',
    icon: getIcon('eva:file-text-fill'),
  },
];

export default navConfig;
