import { Web3ReactProvider } from '@web3-react/core'
import { Web3Provider } from "@ethersproject/providers";

import Router from './routes';
// theme
import ThemeProvider from './theme';
// components
import ScrollToTop from './components/ScrollToTop';
import TransactionsProvider from './context/CryptoNdani';

export default function App() {
  const getLibrary = (provider) => {
    return new Web3Provider(provider);
  };

  return (
    <Web3ReactProvider getLibrary={getLibrary}>
      <TransactionsProvider>
        <ThemeProvider>
          <ScrollToTop />
          <Router />
        </ThemeProvider>
      </TransactionsProvider>
    </Web3ReactProvider>
  );
}
