import { Alert, AlertTitle, Button, Collapse, IconButton, Typography } from '@mui/material';
import PropTypes from 'prop-types';

const AccountBalanceView = ({ showBalanceOpen, setShowBalanceOpen, hasError, refreshAccountBalance, accountInfo }) => (
  <>
    <Collapse in={showBalanceOpen}>
      <Alert
        severity="info"
        variant="outlined"
        action={
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={() => {
              setShowBalanceOpen(false);
            }}
          >
            <Typography variant="body2">Hide</Typography>
          </IconButton>
        }
      >
        {hasError !== undefined ? (
          <>
            <AlertTitle>Account Balance</AlertTitle>
            {accountInfo.accountBalance} <strong>BNB</strong>
          </>
        ) : (
          <AlertTitle>Unable to show your account balance at this time</AlertTitle>
        )}
      </Alert>
    </Collapse>
    {!showBalanceOpen && (
      <Button
        disabled={showBalanceOpen}
        variant="outlined"
        onClick={async () => {
          setShowBalanceOpen(true);
          await refreshAccountBalance();
        }}
      >
        Show Account Balance
      </Button>
    )}
  </>
);

AccountBalanceView.defaultProps = {
  hasError: '',
};

AccountBalanceView.propTypes = {
  showBalanceOpen: PropTypes.bool.isRequired,
  setShowBalanceOpen: PropTypes.func.isRequired,
  hasError: PropTypes.string,
  refreshAccountBalance: PropTypes.func.isRequired,
  accountInfo: PropTypes.object.isRequired,
};

export default AccountBalanceView;
