import React from 'react';
import PropTypes from 'prop-types';
import { AppBar, Box, Dialog, DialogActions, DialogContent, Typography } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const StylesBox = styled(Box)(() => ({
  paddingBottom: 16,
}));

const StylesAppBar = styled(AppBar)(({ theme }) => ({
  position: 'relative',
  backgroundColor: theme.palette.common.white,
  padding: '16px 24px',
  boxShadow: '0 0 1px 0 rgba(0,0,0,0.16)',
}));

const StylesHeaderText = styled(Typography)(({ theme }) => ({
  fontSize: 16,
  color: theme.palette.primary.main,
  textTransform: 'capitalize',
}));

const StyledDialogActions = styled(DialogActions)(() => ({
  display: 'flex',
  justifyContent: 'space-evenly',
}));

const ModalChildren = ({ modalHeader, modalContent, modalActions }) => (
  <>
    {modalHeader && (
      <StylesAppBar>
        <StylesHeaderText variant="h4">{modalHeader}</StylesHeaderText>
      </StylesAppBar>
    )}

    <DialogContent>{modalContent}</DialogContent>
    {modalActions && <StyledDialogActions>{modalActions}</StyledDialogActions>}
  </>
);

const DialogModal = ({
  open,
  responsive = false,
  fullScreen = false,
  maxWidth = 'sm',
  fullWidth = false,
  modalHeader = null,
  modalContent,
  modalActions = null,
  handleClose,
  disableBackdropClose = false,
}) => {
  const theme = useTheme();
  const fullScreenValue = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Dialog
      // TransitionComponent={Transition}
      fullScreen={responsive ? fullScreenValue : fullScreen}
      open={open}
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      onClose={() => {
        // if disableBackdropClose is passed then dont close on clicking the backdrop
        if (!disableBackdropClose) {
          handleClose();
        }
      }}
      aria-labelledby="responsive-dialog-title"
    >
      <StylesBox>
        <ModalChildren modalHeader={modalHeader} modalContent={modalContent} modalActions={modalActions} />
      </StylesBox>
    </Dialog>
  );
};
//
ModalChildren.propTypes = {
  modalHeader: PropTypes.string,
  modalContent: PropTypes.element || PropTypes.string,
  modalActions: PropTypes.element || PropTypes.string,
};

ModalChildren.defaultProps = {
  modalHeader: '',
  modalContent: '',
  modalActions: '',
};

DialogModal.defaultProps = {
  responsive: false,
  fullScreen: false,
  maxWidth: 'sm',
  fullWidth: false,
  modalHeader: null,
  modalActions: null,
  disableBackdropClose: false,
};

DialogModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  modalContent: PropTypes.element.isRequired,
  responsive: PropTypes.bool,
  fullScreen: PropTypes.bool,
  maxWidth: PropTypes.string,
  fullWidth: PropTypes.bool,
  modalHeader: PropTypes.string || PropTypes.node,
  modalActions: PropTypes.element || PropTypes.string,
  disableBackdropClose: PropTypes.bool,
};

export default DialogModal;
