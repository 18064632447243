import { Alert, Button, CardContent, Container, Grid, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Link as RouterLink } from 'react-router-dom';
import { useContext, useState } from 'react';
import Page from '../components/Page';
import SendCryptoForm from '../sections/transact/sendCrypto/SendCryptoForm';
import Iconify from '../components/Iconify';
import { TransactionContext } from '../context/CryptoNdani';
import errorHandler from '../utils/errorHandler';
import AnimatedName from '../components/AnimatedName';
import Account from '../sections/account/account';
import AnimdatedCard from '../components/AnimdatedCard';

const StyledTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontWeight: 700,
  marginTop: theme.spacing(3),
  marginBottom: theme.spacing(3),
}));

const StyledAlert = styled(Alert)(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
}));

const StyledPageTitle = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(4),
  color: theme.palette.grey[600],
}));

const SendCrypto = () => {
  const { accountInfo, errors, refreshAccountBalance } = useContext(TransactionContext);

  const [showBalanceOpen, setShowBalanceOpen] = useState(true);

  return (
    <Page title="Dashboard">
      <Container maxWidth="xl">
        <AnimatedName />
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <StyledPageTitle variant="h4" gutterBottom>
            Send Crypto
          </StyledPageTitle>
          <Button
            variant="contained"
            component={RouterLink}
            to="/transactions"
            startIcon={<Iconify icon="eva:plus-fill" />}
          >
            My Transactions
          </Button>
        </Stack>

        {!accountInfo?.isWalletConnected && (
          <StyledAlert severity="error">{errorHandler('DISCONNECT_ERROR')}</StyledAlert>
        )}
        {/* Take care of disconnection */}
        {errors?.connection &&
          (accountInfo?.isWalletConnected ? (
            <StyledAlert severity="error">{errorHandler('DISCONNECT_ERROR')}</StyledAlert>
          ) : (
            <StyledAlert severity="error">{errorHandler(errors?.connection)}</StyledAlert>
          ))}
        <Grid direction="row" container spacing={5}>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <Account
              accountInfo={accountInfo}
              errors={errors}
              refreshAccountBalance={refreshAccountBalance}
              setShowBalanceOpen={setShowBalanceOpen}
              showBalanceOpen={showBalanceOpen}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
            <Container maxWidth="xl">
              <StyledTitle variant="h4"> Send Crypto</StyledTitle>
              <Typography sx={{ color: 'text.secondary', mb: 5, mt: 2 }}>
                Provide address information below to send Crypto to your intended recipient. This is a secure process
                that involves authentication to your Wallet
              </Typography>
              <AnimdatedCard>
                <CardContent>
                  <SendCryptoForm />
                </CardContent>
              </AnimdatedCard>
            </Container>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default SendCrypto;
