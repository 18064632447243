import { useContext, useEffect, useRef, useState } from 'react';
import { IconButton, ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import Iconify from '../../../components/Iconify';
import { TransactionContext } from '../../../context/CryptoNdani';

const TransactionMoreMenu = ({ payment, setTransactionDetails }) => {
  const theme = useTheme();
  const ref = useRef(null);
  const [isMenuAvailable, setIsMenuAvailable] = useState(payment.status === 1);
  const [isOpen, setIsOpen] = useState(false);

  const { acceptPayment, cancelPayment, accountInfo, isLoading, setIsLoading } = useContext(TransactionContext);

  const handleCancelPayment = async () => {
    try {
      await cancelPayment(payment.id).then((response) => {
        const { status, transactionHash, err } = response;

        if (status) {
          setIsLoading(false);
          setTransactionDetails({
            transactionStateDialog: true,
            transactionStatus: true,
            message: `Payment cancelled successfully. Transaction ID: ${transactionHash}`,
          });
        } else {
          setIsLoading(false);
          setTransactionDetails({ transactionStateDialog: true, transactionStatus: false, message: err });
        }
      });
    } catch (error) {
      setIsLoading(false);
      setTransactionDetails({ transactionStateDialog: true, transactionStatus: false, message: error });
    }
  };

  const handleAcceptPayment = async () => {
    try {
      await acceptPayment(payment.id).then((response) => {
        const { status, transactionHash, err } = response;

        if (status) {
          setIsLoading(false);
          setTransactionDetails({
            transactionStateDialog: true,
            transactionStatus: true,
            message: `Payment accepted successfully. Transaction ID: ${transactionHash}`,
          });
        } else {
          setTransactionDetails({ transactionStateDialog: true, transactionStatus: false, message: err });
        }
      });
    } catch (error) {
      setIsLoading(false);
      setTransactionDetails({ transactionStateDialog: true, transactionStatus: false, message: error });
    }
  };

  useEffect(() => {
    if (payment.status === 1) {
      setIsMenuAvailable(true);
    } else {
      setIsOpen(false);
      setIsMenuAvailable(false);
    }
  }, [payment, accountInfo]);

  return (
    <>
      {isMenuAvailable && (
        <IconButton ref={ref} onClick={() => setIsOpen(true)}>
          <Iconify icon="eva:more-vertical-fill" width={20} height={20} />
        </IconButton>
      )}

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: '100%' },
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        {accountInfo.account && accountInfo.account.toLowerCase() === payment.recepient.toLowerCase() && (
          <MenuItem
            sx={{ color: theme.palette.success.dark }}
            disabled={isLoading}
            onClick={async () => handleAcceptPayment()}
          >
            <ListItemIcon sx={{ color: theme.palette.success.dark }}>
              <Iconify icon="charm:circle-tick" width={24} height={24} />
            </ListItemIcon>
            <ListItemText primary="Accept" primaryTypographyProps={{ variant: 'body2' }} />
          </MenuItem>
        )}

        <MenuItem
          sx={{ color: theme.palette.error.main }}
          disabled={isLoading}
          onClick={async () => handleCancelPayment()}
        >
          <ListItemIcon sx={{ color: theme.palette.error.main }}>
            <Iconify icon="eva:trash-2-outline" width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Cancel" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>
      </Menu>
    </>
  );
};

TransactionMoreMenu.propTypes = {
  payment: PropTypes.object.isRequired,
  setTransactionDetails: PropTypes.func.isRequired,
};

export default TransactionMoreMenu;
