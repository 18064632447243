import { Alert, Container, Grid, Typography } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { useContext } from 'react';
import Page from '../components/Page';
import Wallets from '../sections/transact/Wallets';
import { TransactionContext } from '../context/CryptoNdani';
import errorHandler from '../utils/errorHandler';
import image from '../assets/Graphics/loadRelax.jpg';
import Image from '../components/Image';
import AnimatedName from '../components/AnimatedName';

const ContentStyle = styled('div')(({ theme }) => ({
  margin: 'auto',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(3, 0),
}));

const StyledAlert = styled(Alert)(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
}));

const StyledTitle = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(4),
  color: theme.palette.grey[600],
}));

const Home = () => {
  const theme = useTheme();

  const { errors, accountInfo } = useContext(TransactionContext);

  return (
    <Page title="Dashboard">
      <Container maxWidth="md">
        <Typography variant="h3" sx={{ mt: 10, mb: 1, color: theme.palette.primary.main }}>
          Hi, Welcome to
        </Typography>
        <AnimatedName />
        <Grid direction="row" container spacing={3}>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <ContentStyle>
              {/* Take care of disconnection */}
              {errors?.connection &&
                (accountInfo?.isWalletConnected ? (
                  <StyledAlert severity="error">{errorHandler('DISCONNECT_ERROR')}</StyledAlert>
                ) : (
                  <StyledAlert severity="error">{errorHandler(errors?.connection)}</StyledAlert>
                ))}
              <StyledTitle variant="h4" gutterBottom>
                {accountInfo?.isWalletConnected ? 'Connected to your Wallet' : 'Connect to your Wallet'}
              </StyledTitle>

              <Typography sx={{ color: 'text.secondary', mb: 5 }}>
                {accountInfo?.isWalletConnected ? 'What do you want to do today?' : 'Select your Wallet below.'}
              </Typography>
              <Wallets />
            </ContentStyle>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Image src={image} alt="Image" />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default Home;
