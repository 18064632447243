import { Box, CardContent, Container, Divider, Grid, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { useContext, useState } from 'react';
import { useWeb3React } from '@web3-react/core';
import Page from '../components/Page';
import helpImage from '../assets/Graphics/help.jpg';
import Image from '../components/Image';
import AnimatedName from '../components/AnimatedName';
import { TransactionContext } from '../context/CryptoNdani';
import Account from '../sections/account/account';
import AnimdatedCard from '../components/AnimdatedCard';

const StyledTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary.main,
  fontWeight: 700,
  marginTop: theme.spacing(3),
  marginBottom: theme.spacing(3),
}));

const useStyles = makeStyles((theme) => ({
  coverImage: {
    height: 300,
    marginTop: theme.spacing(4),
  },
  faqSection: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  questionText: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
    color: theme.palette.grey[700],
  },
  answerText: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  addressText: {
    color: theme.palette.primary.main,
  },
  pageTitle: {
    marginTop: theme.spacing(4),
    color: theme.palette.grey[600],
  },
  accountImage: {
    height: 200,
  },
}));

const faqs = [
  {
    question: 'How can I connect to a wallet?',
    answer:
      'On the Home page, with a list of available wallets, select a Wallet of your choice, authenticate on the Wallet popup on the screen proceed to transact.',
  },
  {
    question: 'How can I send crypto?',
    answer:
      'Go to Send Crypto, enter the desired recipients address, select the coin you wish to send. Enter the amount. Click send. This process will open your wallet to allow you to confirm the transaction. Once confirmed, a pop-up will show the transaction progress and feedback at the end of the transaction.',
  },
  {
    question: 'Can I cancel an already initiated transaction?',
    answer:
      'Yes. On the transactions page, select the transaction you wish to cancel, click on the menu to the right of the record, a button will appear with the cancel action. Click this to cancel. This will open your wallet to complete the transaction. A pop-up will also be available to show the transaction progress. Cancelled transactions reverse to Crypto sent back to your account.',
  },
  {
    question: 'What personal data is stored by Crypto.Ndani?',
    answer:
      'None. We do not ask for any personal data to send or receive Crypto. Any transactions are done on your Wallet.',
  },
  {
    question: 'How can I get further support from Crypto.Ndani?',
    answer: 'Reach Crypto.Ndani on twitter @Crypto.ndani',
  },
];

const Help = () => {
  const classes = useStyles();

  const [showBalanceOpen, setShowBalanceOpen] = useState(false);

  const { accountInfo, errors, refreshAccountBalance } = useContext(TransactionContext);

  const { active } = useWeb3React();

  return (
    <Page title="Help">
      <Container maxWidth="xl">
        <Stack direction="column" mb={5}>
          <Grid container direction="row">
            <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <AnimatedName />
                <Typography variant="h4" gutterBottom className={classes.pageTitle}>
                  Get Help Now
                </Typography>
                <Image src={helpImage} alt="help" className={classes.coverImage} />
              </Grid>
              <AnimdatedCard elevation={0}>
                <CardContent>
                  <StyledTitle variant="h4"> Frequently Asked Questions</StyledTitle>
                  {faqs.map((faq) => (
                    <Box className={classes.faqSection} key={faq.question}>
                      <Typography variant="h5" className={classes.questionText}>
                        {faq.question}
                      </Typography>
                      <Divider />
                      <Typography variant="body2" classes={classes.answerText}>
                        {faq.answer}
                      </Typography>
                    </Box>
                  ))}
                </CardContent>
              </AnimdatedCard>
            </Grid>
            <Grid item>
              {active && (
                <Account
                  accountInfo={accountInfo}
                  errors={errors}
                  refreshAccountBalance={refreshAccountBalance}
                  setShowBalanceOpen={setShowBalanceOpen}
                  showBalanceOpen={showBalanceOpen}
                />
              )}
            </Grid>
          </Grid>
        </Stack>
      </Container>
    </Page>
  );
};

export default Help;
