import { Card, CardContent, Divider, Stack, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import Image from '../../components/Image';
import accountImage from '../../assets/Graphics/account.jpg';
import AccountBalanceView from '../../components/AccountBalanceView';
import swingInTopForward from '../../animations/swingInTopForward';

const useStyles = makeStyles((theme) => ({
  coverImage: {
    height: 300,
    marginTop: theme.spacing(4),
  },
  cardTitle: {
    color: theme.palette.secondary.main,
    fontWeight: 700,
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  subTitleText: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
    color: theme.palette.grey[700],
  },
  subText: {
    color: theme.palette.primary.main,
  },
  accountImage: {
    height: 200,
  },
}));

const AnimatedCard = styled(Card)(({ animateCard }) => ({
  animation: animateCard && `${swingInTopForward} 1.0s cubic-bezier(0.175, 0.885, 0.320, 1.275) both`,
}));

const Account = ({ accountInfo, showBalanceOpen, setShowBalanceOpen, errors, refreshAccountBalance }) => {
  const classes = useStyles();

  const [animateCard, setAnimateCard] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      // animation
      setAnimateCard(true);
    }, 100);
  }, [animateCard]);

  return (
    <AnimatedCard animateCard={animateCard}>
      <CardContent>
        <Image src={accountImage} alt="accounts" className={classes.accountImage} />
        <Stack direction="column">
          <Typography variant="h4" className={classes.cardTitle}>
            Account Information
          </Typography>
          <AccountBalanceView
            showBalanceOpen={showBalanceOpen}
            setShowBalanceOpen={setShowBalanceOpen}
            hasError={errors?.balance}
            refreshAccountBalance={refreshAccountBalance}
            accountInfo={accountInfo}
          />
          <Typography variant="caption" className={classes.subTitleText}>
            ADDRESS
          </Typography>
          <Divider />
          <Typography variant="body2" classes={classes.subText}>
            {accountInfo?.account}
          </Typography>
        </Stack>
      </CardContent>
    </AnimatedCard>
  );
};

Account.propTypes = {
  accountInfo: PropTypes.object.isRequired,
  showBalanceOpen: PropTypes.bool.isRequired,
  setShowBalanceOpen: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  refreshAccountBalance: PropTypes.func.isRequired,
};

export default Account;
