import PropTypes from 'prop-types';
import { useContext, useEffect, useState } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { Avatar, Box, Button, Chip, CircularProgress, Drawer, Link, Stack, Typography } from '@mui/material';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import { makeStyles } from '@mui/styles';
import displayAccount from '../../_mock/account';
import useResponsive from '../../hooks/useResponsive';
import Scrollbar from '../../components/Scrollbar';
import NavSection from '../../components/NavSection';
import navConfig from './NavConfig';
import { TransactionContext } from '../../context/CryptoNdani';
import shortenAddress from '../../utils/shortenAddress';
import Dialog from '../../components/Dialog';
import StatusIcon from '../../components/StatusIcon';
import LoadingDialog from '../../components/LoadingDialog';

const DRAWER_WIDTH = 280;

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    width: DRAWER_WIDTH,
  },
}));

const AccountStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: theme.palette.grey[500_12],
}));

const StylesChip = styled(Chip)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  marginTop: theme.spacing(2),
}));

// ----------------------------------------------------------------------

const useStyles = makeStyles(() => ({
  dialogContent: {
    textAlign: 'center',
  },
}));

const DashboardSidebar = ({ isOpenSidebar, onCloseSidebar }) => {
  const classes = useStyles();
  const { pathname } = useLocation();
  const { accountInfo, claimProtocolFees } = useContext(TransactionContext);

  const [isClaimProtocolFeesButtonDisabled, setIsClaimProtocolFeesButtonDisabled] = useState(false);

  const isDesktop = useResponsive('up', 'lg');

  const [claimFeeDetails, setClaimFeeDetails] = useState({
    open: false,
    status: false,
    message: '',
  });

  const { open, status, message, body } = claimFeeDetails;
  const closeDialog = () => {
    setClaimFeeDetails({ open: false, status: false, message: '' });
  };

  const claimFees = async () => {
    setIsClaimProtocolFeesButtonDisabled(true);

    try {
      await claimProtocolFees().then((response) => {
        const { status, transactionHash, err } = response;

        if (status) {
          setClaimFeeDetails({
            open: true,
            status: true,
            message: `Claim Successful, Transfer initiated with transaction ID: ${transactionHash}`,
            body: '',
          });
        } else {
          setClaimFeeDetails({ open: true, status: false, message: err });
        }
      });
    } catch (error) {
      setClaimFeeDetails({ open: true, status: false, message: error });
    }

    setIsClaimProtocolFeesButtonDisabled(false);
  };

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': {
          height: 1,
          display: 'flex',
          flexDirection: 'column',
        },
      }}
    >
      <Box sx={{ mb: 5, mt: 3, mx: 2.5, textAlign: 'center' }}>
        <Link underline="none" component={RouterLink} to="#">
          <AccountStyle>
            <Avatar src={displayAccount.photoURL} alt="photoURL" />
            <Box sx={{ ml: 2 }}>
              <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                {displayAccount.displayName}
              </Typography>
            </Box>
          </AccountStyle>
        </Link>

        <StylesChip
          avatar={<CreditCardIcon />}
          color="primary"
          label={accountInfo?.account ? shortenAddress(accountInfo?.account) : ''}
        />

        {accountInfo.isContractOwner && (
          <Button
            variant="outlined"
            color="primary"
            sx={{ mt: 2 }}
            startIcon={isClaimProtocolFeesButtonDisabled ? <CircularProgress size={20} /> : <AdminPanelSettingsIcon />}
            disabled={isClaimProtocolFeesButtonDisabled}
            onClick={async () => claimFees()}
          >
            {!isClaimProtocolFeesButtonDisabled ? 'Claim Protocol Fees' : 'Please wait'}
          </Button>
        )}
      </Box>

      <NavSection navConfig={navConfig} />

      <Box sx={{ flexGrow: 1 }} />

      <Box sx={{ px: 2.5, pb: 3, mt: 10 }}>
        <Stack alignItems="center" spacing={3} sx={{ pt: 5, borderRadius: 2, position: 'relative' }}>
          <Box
            component="img"
            src="/static/illustrations/illustration_avatar.png"
            sx={{ width: 100, position: 'absolute', top: -50 }}
          />

          <Box sx={{ textAlign: 'center' }}>
            <Typography gutterBottom variant="h6">
              Need some help?
            </Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              Get started.
            </Typography>
          </Box>

          <Stack direction="column">
            <Button variant="outlined" component={RouterLink} to="/help" sx={{ mb: 2 }}>
              Help Page
            </Button>
            <Button href="https://youtu.be/r1VYQEKTAaI" target="_blank" variant="contained">
              Check our YouTube Tutorial
            </Button>
          </Stack>
        </Stack>
      </Box>
    </Scrollbar>
  );

  return (
    <RootStyle>
      <Dialog
        open={open}
        modalContent={
          <Box className={classes.dialogContent}>
            <StatusIcon
              status={status ? 'success' : 'error'}
              text={status ? 'Claim Sent Successfully' : 'Claim failed!'}
            />
            <Typography variant="body1"> {message}</Typography>
          </Box>
        }
        modalActions={
          <Button variant="contained" onClick={() => closeDialog()} color="primary" autoFocus>
            Close
          </Button>
        }
        handleClose={closeDialog}
      />
      <LoadingDialog isLoading={isClaimProtocolFeesButtonDisabled} />
      {!isDesktop && (
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: DRAWER_WIDTH },
          }}
        >
          {renderContent}
        </Drawer>
      )}

      {isDesktop && (
        <Drawer
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: DRAWER_WIDTH,
              bgcolor: 'background.default',
              borderRightStyle: 'dashed',
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </RootStyle>
  );
};

DashboardSidebar.propTypes = {
  isOpenSidebar: PropTypes.bool.isRequired,
  onCloseSidebar: PropTypes.func.isRequired,
};

export default DashboardSidebar;
