import PropTypes from 'prop-types';
import { alpha, styled } from '@mui/material/styles';
import { AppBar, Box, Button, Chip, IconButton, Stack, Toolbar } from '@mui/material';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import { useContext } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useWeb3React } from '@web3-react/core';
import Iconify from '../../components/Iconify';
import shortenAddress from '../../utils/shortenAddress';
import { TransactionContext } from '../../context/CryptoNdani';

const DRAWER_WIDTH = 280;
const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;

const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: 'none',
  backdropFilter: 'blur(6px)',
  WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  backgroundColor: alpha(theme.palette.primary.dark, 0.72),
  [theme.breakpoints.up('lg')]: {
    width: `calc(100% - ${DRAWER_WIDTH + 1}px)`,
  },
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));

const DashboardNavbar = ({ onOpenSidebar }) => {
  const navigate = useNavigate();
  const { accountInfo } = useContext(TransactionContext);
  const { deactivate, active } = useWeb3React();

  const handleLogout = () => {
    deactivate();

    navigate('/');
  };

  return (
    <RootStyle>
      <ToolbarStyle>
        <IconButton onClick={onOpenSidebar} sx={{ mr: 1, color: 'text.primary', display: { lg: 'none' } }}>
          <Iconify icon="eva:menu-2-fill" />
        </IconButton>

        <Box sx={{ flexGrow: 1 }} />

        {active ? (
          <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 1.5 }}>
            <Chip avatar={<CreditCardIcon />} color="primary" label={shortenAddress(accountInfo?.account)} />

            {/* <NotificationsPopover /> */}

            <IconButton onClick={handleLogout} sx={{ mr: 2, color: 'text.primary' }}>
              <Iconify icon="carbon:logout" />
            </IconButton>
          </Stack>
        ) : (
          <Button startIcon={<CreditCardIcon />} variant="contained" component={RouterLink} to="/">
            Connect Wallet
          </Button>
        )}
      </ToolbarStyle>
    </RootStyle>
  );
};

DashboardNavbar.propTypes = {
  onOpenSidebar: PropTypes.func.isRequired,
};

export default DashboardNavbar;
