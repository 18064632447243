import { Box, CircularProgress, Stack, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import React from 'react';
import Dialog from './Dialog';

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    textAlign: 'center',
    alignContent: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: theme.spacing(2),
  },
  modalTitle: {
    color: theme.palette.primary.main,
    fontWeight: 700,
    fontSize: 24,
    textAlign: 'center',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

const LoadingDialog = ({ isLoading }) => {
  const classes = useStyles();
  return (
    <Dialog
      open={isLoading}
      modalContent={
        <Box>
          <Stack direction="column" className={classes.dialogContent}>
            <CircularProgress size={45} thickness={4.5} />
            <Typography className={classes.modalTitle}>Transaction in progress...</Typography>
            <Typography variant="body1">
              {isLoading
                ? 'This will take a moment.'
                : 'An error occurred while starting the transaction, please retry'}
            </Typography>
          </Stack>
        </Box>
      }
      // modalActions={
      //   <Button variant="contained" onClick={() => closeDialog()} color="primary" autoFocus>
      //     Close
      //   </Button>
      // }
      handleClose={() => {}}
    />
  );
};

LoadingDialog.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  // closeDialog: PropTypes.func.isRequired,
};

export default LoadingDialog;
