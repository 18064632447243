import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import trackingInExpandFwd from '../animations/trackingInExpandFwd';

const StylesTypography = styled(Typography)(({ theme, animate }) => ({
  color: theme.palette.secondary.main,
  animation: animate && `${trackingInExpandFwd} 1.3s cubic-bezier(0.215, 0.610, 0.355, 1.000) both`,
}));

const AnimatedName = () => {
  const [animate, setAnimate] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      // animation
      setAnimate(true);
    }, 100);
  }, [animate]);

  return (
    <StylesTypography variant="h3" animate={animate}>
      Crypto.Ndani
    </StylesTypography>
  );
};

export default AnimatedName;
